/*
 * @Author: your name
 * @Date: 2021-11-12 14:14:04
 * @LastEditTime: 2021-11-15 11:12:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\video-control.js
 */
import request from '@/utils/request.js';
/**
 * 获取列表
 * @returns {Promise}
 */
export function getList(params) {
  return request({
    url: '/traffic-construction/device/page',
    method: 'get',
    params
  });
}
export function getOrgList(params) {
  return request({
    url: '/traffic-construction/device/orgList',
    method: 'get',
    params
  });
}
// 获取小类下拉
export function getDeviceTypeList(params) {
  return request({
    url: '/roses-platform/deviceType/list',
    method: 'get',
    params
  });
}
// 根据机构id获取系统唯一标识
export function getSystemUniqueNo(params) {
  return request({
    url: '/roses-platform/sysOrg/getSystemUniqueNo',
    method: 'get',
    params
  });
}
// 根据机构id获取系统唯一标识
export function SysDictDataByCamera(params) {
  return request({
    url: '/traffic-construction/device/getSysDictDataByCamera',
    method: 'get',
    params
  });
}
// 根据机构id获取系统唯一标识
export function getTree(params) {
  return request({
    url: '/traffic-construction/device/addOrEditOrgList',
    method: 'get',
    params
  });
}
export function postAdd(data) {
  return request({
    url: '/traffic-construction/device/add',
    method: 'post',
    data
  });
}
export function postDetail(data) {
  return request({
    url: '/traffic-construction/device/detail',
    method: 'post',
    data
  });
}
export function postEdit(data) {
  return request({
    url: '/traffic-construction/device/edit',
    method: 'post',
    data
  });
}

export function deleteRickPoint(data) {
  return request({
    url: '/traffic-construction/device/delete',
    method: 'post',
    data
  });
}
